var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-modal',{attrs:{"id":_vm.modal_id,"title":`Seleccionar ${_vm.$getVisibleNames(
    'mesh.cycle',
    true,
    'Ciclos Formativos'
  )}`,"size":"lg","hide-footer":""}},[_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.new-egress-profile-cycle-modal",modifiers:{"new-egress-profile-cycle-modal":true}}],staticClass:"mb-2"},[_vm._v("Crear "+_vm._s(_vm.$getVisibleNames("mesh.cycle", false, "Ciclo Formativo")))]),_c('EgressProfileCycleTable',{attrs:{"allows_crud":false,"selection_mode":true,"egress_profile_id":_vm.egress_profile.id,"selected_ids":_vm.selected_cycle_ids},on:{"cycle_changed":_vm.slotCycleChanged}}),_c('div',[_c('b-modal',{attrs:{"id":"new-egress-profile-cycle-modal","title":`Crear ${_vm.$getVisibleNames(
        'mesh.cycle',
        false,
        'Ciclo Formativo'
      )} en ${_vm.$getVisibleNames(
        'mesh.egressprofile',
        false,
        'Perfil de Egreso'
      )}`,"size":"lg","hide-footer":""}},[_c('EgressProfileCycleForm',{attrs:{"show_title":false,"modal_id":_vm.modal_id,"egress_profile":_vm.egress_profile,"egress_profile_cycles":_vm.selected_cycle_ids},on:{"created":_vm.slotCreatedCycle,"close":function($event){return _vm.hideModal()}}})],1),_c('div',{staticClass:"col",staticStyle:{"text-align":"right"}},[_c('b-button',{staticClass:"mr-1",attrs:{"size":"sm"},on:{"click":_vm.accept}},[_vm._v("Guardar")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }